import React from 'react';

const Logo = ({ className }) => (
    <svg viewBox="0 0 160 26" className={className}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M2.578 2.186v10.08h4.67c4.234 0 6.586-1.68 6.586-5.208 0-3.562-2.352-4.872-6.586-4.872h-4.67zM16.388 25h-2.286L7.584 13.946H2.578V25H.562V.506h7.156c4.872 0 8.165 1.78 8.165 6.552 0 3.897-2.385 6.148-6.115 6.72L16.388 25zM57.446 25.437c-3.561 0-6.384-1.479-8.332-3.663l1.243-1.41c1.78
                2.015 4.3 3.258 7.09 3.258 3.628 0 5.88-1.915 5.88-4.704 0-2.956-1.916-3.897-4.335-4.972l-3.662-1.613c-2.184-.975-4.973-2.52-4.973-6.115 0-3.63 3.091-6.15 7.257-6.15 2.957 0 5.376 1.278 6.922 2.958l-1.142 1.31c-1.445-1.478-3.36-2.453-5.78-2.453-3.124 0-5.208 1.647-5.208 4.167 0 2.788 2.386 3.83
                4.167 4.603l3.662 1.613c2.789 1.243 5.141 2.788 5.141 6.518 0 3.763-3.125 6.653-7.93 6.653zM70.45 25V.506h13.54v1.713H72.466v8.938h9.71v1.713h-9.71v10.383H84.36V25H70.45zm19.252 0V.506h2.016v22.747h11.156V25H89.702zm17.54 0V.506h2.016V25h-2.016zm17.404.437c-4.401 0-8.366-2.285-8.366-10.013V.506h2.016v14.817c0 6.384 2.957 8.3 6.35 8.3 3.461 0 6.485-1.916 6.485-8.3V.506h1.915v14.918c0 7.728-3.998 10.013-8.4 10.013zM140.07 25V.506h2.688l5.04 14.078 1.915 5.376h.134c.639-1.78 1.21-3.595 1.815-5.376L156.7.506h2.688V25h-1.915V9.477c0-2.016.134-4.704.235-6.754h-.135L155.66 8.2l-5.14 14.18h-1.613L143.73 8.2l-1.915-5.477h-.134c.1 2.05.235 4.738.235 6.754V25h-1.848z"
                fill="#B76E79"
                fillRule="nonzero"
            />
            <g stroke="#B76E79" strokeLinejoin="round" strokeWidth={1.4}>
                <path
                    d="M29.459 4.221c.702-.783 1.24-1.36 1.614-1.734a28.88 28.88 0 011.466-1.346c.629.546 1.12.994 1.47 1.346.353.352.893.939 1.621 1.761"
                    strokeLinecap="round"
                />
                <path d="M32.26 25.301c2.498.49 7.139.193 9.732-3.59.223-.327.8-1.616.996-2.821.145-.89-.126-2.422-.183-2.693-.234-1.105-.58-2.555-.813-3.538-.503-2.12-1.01-4.775-.612-8.078.072-.603.362-1.45.869-2.54-3.117.772-5.492 1.618-7.125 2.54-3.614 2.04-6.522 5.712-7.32 7.903-.33.91-.798 2.224-1.004 3.452-.293 1.751-.181 3.507.216 4.778.405 1.293 1.1 2.38 1.95 3.065a7.664 7.664 0 003.293 1.522z" />
                <path d="M32.877 25.301c-2.5.49-7.14.193-9.733-3.59-.223-.327-.8-1.616-.996-2.821-.145-.89.126-2.422.184-2.693.233-1.105.579-2.555.812-3.538.504-2.12 1.01-4.775.613-8.078-.073-.603-.363-1.45-.87-2.54 3.117.772 5.492 1.618 7.126 2.54 3.613 2.04 6.521 5.712 7.318 7.903.332.91.8 2.224 1.005 3.452.293 1.751.182 3.507-.216 4.778-.405 1.293-1.1 2.38-1.95 3.065a7.664 7.664 0 01-3.293 1.522z" />
            </g>
        </g>
    </svg>
);

export default Logo;
