// AUTH
export const SET_AUTH = 'SET_AUTH';
export const CLEAR_AUTH = 'CLEAR_AUTH';

// ACCOUNT
export const SET_ACCOUNT_DETAILS = 'SET_ACCOUNT_DETAILS';
export const CLEAR_ACCOUNT_DETAILS = 'CLEAR_ACCOUNT_DETAILS';

// DIALOGS
export const OPEN_CONTACT_US = 'OPEN_CONTACT_US';
export const CLOSE_CONTACT_US = 'CLOSE_CONTACT_US';

// CHECKOUT
export const SET_CHECKOUT_DATA = 'SET_CHECKOUT_DATA';
export const CLEAR_CHECKOUT_DATA = 'CLEAR_CHECKOUT_DATA';