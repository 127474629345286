import * as Types from '../constants/action-types';
import { AUTH_TOKEN } from '../constants/local-storage';
import API from '../api/roselium-api';

export function login({ email, password, persistent = true }) {
    return async (dispatch, getState) => {
        const result = await API.login({ email, password });

        if (result.ok) {
            const { token } = result.data;

            if (persistent) {
                localStorage.setItem(AUTH_TOKEN, token);
            } else {
                sessionStorage.setItem(AUTH_TOKEN, token);
            }

            API.setToken(token);

            const getUser = await API.getUser();

            if (getUser.ok) {
                const { claims, user } = getUser.data;

                await dispatch({
                    type: Types.SET_AUTH,
                    data: {
                        authenticated: true,
                        claims,
                        user
                    }
                });
            } else {
                localStorage.removeItem(AUTH_TOKEN);
                sessionStorage.removeItem(AUTH_TOKEN);
                API.clearToken();
                await dispatch({ type: Types.CLEAR_AUTH });
            }
        }

        return result;
    };
}

export function logout() {
    return async (dispatch, getState) => {
        localStorage.removeItem(AUTH_TOKEN);
        sessionStorage.removeItem(AUTH_TOKEN);
        API.clearToken();
        await dispatch({ type: Types.CLEAR_CHECKOUT_DATA });
        await dispatch({ type: Types.CLEAR_ACCOUNT_DETAILS });
        await dispatch({ type: Types.CLEAR_AUTH });
    }
}

export function signup({
    email,
    password,
    fname,
    lname,
    subscribe_newsletter,
    persistent = true
}) {
    return async (dispatch, getState) => {
        const result = await API.signup({
            email,
            password,
            first_name: fname,
            last_name: lname,
            subscribe_newsletter
        });

        if (result.ok) {
            const { token } = result.data;

            if (persistent) {
                localStorage.setItem(AUTH_TOKEN, token);
            } else {
                sessionStorage.setItem(AUTH_TOKEN, token);
            }

            API.setToken(token);

            const getUser = await API.getUser();

            if (getUser.ok) {
                const { claims, user } = getUser.data;

                await dispatch({
                    type: Types.SET_AUTH,
                    data: {
                        authenticated: true,
                        claims,
                        user
                    }
                });
            } else {
                localStorage.removeItem(AUTH_TOKEN);
                sessionStorage.removeItem(AUTH_TOKEN);
                API.clearToken();
                await dispatch({ type: Types.CLEAR_AUTH });
            }
        }

        return result;
    };
}

export function confirmEmail({ token }) {
    return async (dispatch, getState) => {
        return await API.confirmEmail({ token });
    };
}

export function resendConfirmEmail({ user_id }) {
    return async (dispatch, getState) => {
        return await API.resendConfirmEmail({ user_id });
    };
}

export function forgotPassword({ email }) {
    return async (dispatch, getState) => {
        return await API.forgotPassword({ email });
    };
}

export function resetPassword({ token, password }) {
    return async (dispatch, getState) => {
        const result = await API.resetPassword({ token, password });

        if (!result.ok) {
            return result;
        }

        const { user } = result.data;

        if (!user) {
            return result;
        }

        const { email } = user;

        await dispatch(
            login(
                {
                    email,
                    password,
                    persistent: true
                }
            )
        );

        return result;
    };
}

export function setShippingAddress({
    fname,
    lname,
    addressln1,
    addressln2,
    city,
    state,
    postal
}) {
    return async (dispatch, getState) => {
        const request = {
            first_name: fname,
            last_name: lname,
            address_ln1: addressln1,
            address_ln2: addressln2,
            city,
            state,
            postal_code: postal
        };

        const result = await API.setShippingAddress(request);

        return result;
    };
}

export function setDefaultSource({ token }) {
    return async (dispatch, getState) => {
        const request = {
            source_token: token.id
        };

        const result = await API.setDefaultSource(request);

        return result;
    };
}

export function getAccountDetails() {
    return async (dispatch, getState) => {
        const result = await API.getAccountDetails();

        if (result.ok) {
            const account = result.data;

            const {
                first_name,
                last_name,
                default_card,
                shipping,
                subscriptions
            } = account;

            let formatted_shipping = null;

            if (shipping) {
                const {
                    address_ln1,
                    address_ln2,
                    city,
                    state,
                    postal_code
                } = shipping;

                formatted_shipping = {
                    addressln1: address_ln1,
                    addressln2: address_ln2,
                    city,
                    state,
                    postal: postal_code
                };
            }

            await dispatch({
                type: Types.SET_ACCOUNT_DETAILS,
                data: {
                    fname: first_name,
                    lname: last_name,
                    defaultCard: default_card,
                    shipping: formatted_shipping,
                    subscriptions
                }
            });
        }

        return result;
    };
}

export function subscribeNewsletter({ email }) {
    return async (dispatch, getState) => {
        return await API.subscribeNewsletter({ email });
    };
}

export function unsubscribeNewsletter({ email }) {
    return async (dispatch, getState) => {
        return await API.unsubscribeNewsletter({ email });
    };
}

export function cancelSubscription({ subscription, reason }) {
    return async (dispatch, getState) => {
        return await API.cancelSubscription(
            {
                subscription_id: subscription,
                reason: reason || 'No reason specified'
            }
        );
    };
}

export function renewSubscription({ subscription }) {
    return async (dispatch, getState) => {
        return await API.renewSubscription(
            {
                subscription_id: subscription
            }
        );
    };
}
