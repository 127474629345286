import { createStore, applyMiddleware, compose } from 'redux';
import Thunk from 'redux-thunk';
import AppReducer from '../reducers/app-reducer';

const configStore = (initialState) => {
    const enhancer = compose(applyMiddleware(Thunk));

    return createStore(AppReducer, initialState, enhancer);
};

export default configStore;
