import React, { useContext, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import clsx from 'clsx';
import { Popover, IconButton, Badge, Slide, Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { User, ShoppingBag, Menu, Truck } from 'react-feather';

import StoreContext from '../context/store-context';
import Logo from './logo';

import styles from '../styles/header.module.scss';

const useStyles = makeStyles(theme => ({
    paper: {
        width: '100vw',
        maxWidth: 'none',
        borderRadius: 0,
        left: 0,
        right: 0,
        padding: '1.5rem'
    }
}));

const countItems = (lineItems) => {
    if (!lineItems) {
        return 0;
    }

    return lineItems.reduce((total, x) => (total + x.quantity), 0);
};

const Header = ({ auth }) => {
    const context = useContext(StoreContext);
    const { checkout } = context;

    const classes = useStyles();

    const header = useRef(null);
    const getAnchor = () => (header && header.current ? header.current : null);

    const [open, setOpen] = useState(false);
    const [quantity, setQuantity] = useState(countItems(checkout ? checkout.lineItems : []));

    useEffect(() => {
        setQuantity(countItems(checkout ? checkout.lineItems : []));
    }, [checkout]);

    const toggleMenu = () => setOpen(!open);
    const closeMenu = () => setOpen(false);

    const Links = ({ className }) => (
        <div className={clsx(styles.links, className)}>
            <Link to='/#how_it_works' onClick={closeMenu} activeClassName={styles.active}>HOW IT WORKS</Link>
            <Link to='/monthly-jewelry-subscription/' onClick={closeMenu} activeClassName={styles.active}>BECOME A MEMBER</Link>
            <Link to='/store/' onClick={closeMenu} activeClassName={styles.active}>STORE</Link>
            <Link to='/our-partners/' onClick={closeMenu} activeClassName={styles.active}>OUR PARTNERS</Link>
        </div>
    );

    return (
        <>
            <section className={styles.alert}>
                <div className={styles.alertContent}>
                    <div className={styles.perks}>
                        <div className={styles.perk}>
                            <Truck size={20} className={styles.icon} />
                            <span className={styles.label}>FREE SHIPPING FOR STORE ORDERS OVER $50</span>
                        </div>
                    </div>
                    <ul className={styles.announce}>
                        <li>New brand added every month!</li>
                        <li><Link to='/store/'>Shop now!</Link></li>
                    </ul>
                </div>
            </section>
            <header ref={header}>
                <nav>
                    <div className={styles.mobile}>
                        <IconButton
                            onClick={toggleMenu}
                            title='Open navigation menu'
                        >
                            <Menu size={24} />
                        </IconButton>
                    </div>
                    <Link
                        to='/'
                        aria-label='Home'
                        className={styles.brand}
                        onClick={closeMenu}
                    >
                        <Logo className={styles.logo} />
                    </Link>
                    <Links />
                    <div className={styles.actions}>
                        <IconButton
                            component={Link}
                            to={auth.authenticated ? '/my-account/' : '/login/'}
                            title='View your account'
                            className={styles.user}
                            onClick={closeMenu}
                        >
                            <User size={24} />
                        </IconButton>
                        <IconButton
                            component={Link}
                            to='/cart/'
                            title='View your shopping cart'
                            className={styles.cart}
                            onClick={closeMenu}
                        >
                            <Badge
                                invisible={!Boolean(quantity)}
                                badgeContent={quantity}
                                color='primary'
                            >
                                <ShoppingBag size={24} />
                            </Badge>
                        </IconButton>
                    </div>
                </nav>
            </header>
            {typeof window !== 'undefined' &&
                <Popover
                    open={open}
                    anchorEl={getAnchor}
                    onClose={closeMenu}
                    marginThreshold={0}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    TransitionComponent={Slide}
                    TransitionProps={{
                        direction: 'down'
                    }}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        invisible: false
                    }}
                    closeAfterTransition={false}
                    hideBackdrop={false}
                    keepMounted={true}
                    style={{ zIndex: 900 }}
                    classes={{
                        paper: classes.paper
                    }}
                >
                    <Links className={styles.drawerLinks} />
                </Popover>
            }
        </>
    );
};

const mapState = (state) => ({ auth: state.auth });

export default connect(mapState, null)(Header);
