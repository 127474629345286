import * as Types from '../constants/action-types';
import { AUTH_TOKEN } from '../constants/local-storage';
import API from '../api/roselium-api';

const getToken = () => {
    const localStorageToken = localStorage.getItem(AUTH_TOKEN);

    if (localStorageToken) {
        return localStorageToken;
    }

    const sessionStorageToken = sessionStorage.getItem(AUTH_TOKEN);

    if (sessionStorageToken) {
        return sessionStorageToken;
    }

    return null;
}

export function checkAuth() {
    return async (dispatch, getState) => {
        const token = getToken();

        if (!token) {
            await dispatch({ type: Types.CLEAR_AUTH });
            return false;
        }

        API.setToken(token);

        const result = await API.getUser();

        if (result.ok) {
            const { claims, user } = result.data;

            if (
                typeof (claims) === 'object' && Object.keys(claims).length > 0 &&
                typeof (user) === 'object' && Object.keys(user).length > 0
            ) {
                await dispatch({
                    type: Types.SET_AUTH,
                    data: {
                        authenticated: true,
                        claims,
                        user
                    }
                });
                return true;
            }
        }

        localStorage.removeItem(AUTH_TOKEN);
        sessionStorage.removeItem(AUTH_TOKEN);
        API.clearToken();
        await dispatch({ type: Types.CLEAR_AUTH });
        return false;
    };
}
