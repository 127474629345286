import axios from 'axios';

class RoseliumAPI {
    constructor() {
        axios.defaults.baseURL = process.env.GATSBY_API_URL;
    }

    setToken(token) {
        axios.defaults.headers.common['Authorization'] = token;
    }

    clearToken() {
        delete axios.defaults.headers.common['Authorization'];
    }

    getErrorMsg(e) {
        return e.response && e.response.data ? e.response.data.error : null;
    }

    async getUser() {
        let response;

        try {
            response = await axios.get('/user');
        } catch {
            return {
                ok: false
            };
        }

        return {
            ok: true,
            data: response.data
        };
    }

    async login({ email, password }) {
        let response;

        try {
            response = await axios.post(
                '/login',
                {
                    email,
                    password
                }
            );
        } catch (e) {
            return {
                ok: false,
                error: this.getErrorMsg(e)
            };
        }

        return {
            ok: true,
            data: response.data
        };
    }

    async signup({
        email,
        password,
        first_name,
        last_name,
        subscribe_newsletter
    }) {
        let response;

        try {
            response = await axios.put(
                '/signup',
                {
                    email,
                    password,
                    first_name,
                    last_name,
                    subscribe_newsletter
                }
            );
        } catch (e) {
            return {
                ok: false,
                error: this.getErrorMsg(e)
            };
        }

        return {
            ok: true,
            data: response.data
        };
    }

    async confirmEmail({ token }) {
        let response;

        try {
            response = await axios.post(
                '/confirm_email',
                {
                    token
                }
            );
        } catch (e) {
            return {
                ok: false,
                error: this.getErrorMsg(e)
            };
        }

        return {
            ok: true,
            data: response.data
        };
    }

    async resendConfirmEmail({ user_id }) {
        let response;

        try {
            response = await axios.post(
                '/resend_confirm_email',
                {
                    user_id
                }
            );
        } catch (e) {
            return {
                ok: false,
                error: this.getErrorMsg(e)
            };
        }

        return {
            ok: true,
            data: response.data
        };
    }

    async forgotPassword({ email }) {
        try {
            await axios.post(
                '/forgot_password',
                {
                    email
                }
            );
        } catch (e) {
            return {
                ok: false,
                error: this.getErrorMsg(e)
            };
        }

        return {
            ok: true
        };
    }

    async resetPassword({ token, password }) {
        let response;

        try {
            response = await axios.post(
                '/reset_password',
                {
                    token,
                    password
                }
            );
        } catch (e) {
            return {
                ok: false,
                error: this.getErrorMsg(e)
            };
        }

        return {
            ok: true,
            data: response.data
        };
    }

    async setShippingAddress({
        first_name,
        last_name,
        address_ln1,
        address_ln2,
        city,
        state,
        postal_code
    }) {
        let response;

        try {
            response = await axios.post(
                '/account/shipping_address',
                {
                    first_name,
                    last_name,
                    address_ln1,
                    address_ln2,
                    city,
                    state,
                    postal_code
                }
            );
        } catch (e) {
            return {
                ok: false,
                error: this.getErrorMsg(e)
            };
        }

        return {
            ok: true,
            data: response.data
        };
    }

    async setDefaultSource({ source_token }) {
        let response;

        try {
            response = await axios.post(
                '/account/default_source',
                {
                    source_token
                }
            );
        } catch (e) {
            return {
                ok: false,
                error: this.getErrorMsg(e)
            };
        }

        return {
            ok: true,
            data: response.data
        };
    }

    async getAccountDetails() {
        let response;

        try {
            response = await axios.get('/account/details');
        } catch (e) {
            return {
                ok: false,
                error: this.getErrorMsg(e)
            };
        }

        return {
            ok: true,
            data: response.data
        };
    }

    async subscribeNewsletter({ email }) {
        let response;

        try {
            response = await axios.put(
                '/newsletter',
                {
                    email
                }
            );
        } catch (e) {
            return {
                ok: false,
                error: this.getErrorMsg(e)
            };
        }

        return {
            ok: true,
            data: response.data
        };
	}
	
	async unsubscribeNewsletter({ email }) {
        let response;

        try {
            response = await axios.post(
                '/newsletter/unsubscribe',
                {
                    email
                }
            );
        } catch (e) {
            return {
                ok: false,
                error: this.getErrorMsg(e)
            };
        }

        return {
            ok: true,
            data: response.data
        };
    }

    async getCoupon({ id }) {
        const path = `/coupons/${encodeURIComponent(id)}`;

        let response;

        try {
            response = await axios.get(path);
        } catch {
            return {
                ok: false
            };
        }

        return {
            ok: true,
            data: response.data
        };
    }

    async createSubscription({ plan_id, source_token, coupon_id, metadata }) {
        let response;

        try {
            response = await axios.put(
                '/subscriptions',
                {
                    plan_id,
                    source_token,
                    coupon_id,
                    metadata
                }
            );
        } catch (e) {
            if (e.response && e.response.data) {
                const { error, subscription_id } = e.response.data;

                return {
                    ok: false,
                    error,
                    subscription_id
                };
            }

            return {
                ok: false
            };
        }

        const { error, subscription_id } = response.data;

        return {
            ok: true,
            error,
            subscription_id
        };
    }

    async subscriptionPayment({ subscription_id, source_token }) {
        let response;

        try {
            response = await axios.post(
                '/subscriptions/payment',
                {
                    subscription_id,
                    source_token
                }
            );
        } catch (e) {
            return {
                ok: false,
                error: this.getErrorMsg(e),
                subscription_id
            };
        }

        const { error } = response.data;

        return {
            ok: true,
            error,
            subscription_id
        };
    }

    async cancelSubscription({ subscription_id, reason }) {
        try {
            await axios.post(
                '/subscriptions/cancel',
                {
                    subscription_id,
                    reason
                }
            );
        } catch (e) {
            return {
                ok: false,
                error: this.getErrorMsg(e)
            };
        }

        return {
            ok: true,
            error: null
        };
    }

    async renewSubscription({ subscription_id }) {
        try {
            await axios.post(
                '/subscriptions/renew',
                {
                    subscription_id
                }
            );
        } catch (e) {
            return {
                ok: false,
                error: this.getErrorMsg(e)
            };
        }

        return {
            ok: true,
            error: null
        };
    }
}

const singleton = new RoseliumAPI();

export default singleton;
