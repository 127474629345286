import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Header from '../components/header';
import Footer from '../components/footer';
import CartDialog from '../components/cart-dialog';
import * as AuthActions from '../actions/auth-actions';

import '../styles/site.scss';
import styles from '../styles/layout.module.scss';

if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    require('smooth-scroll')(
        'a[href*="#"]'
    );
}

const Layout = (props) => {
    useEffect(() => {
        if (typeof window !== 'undefined' && !props.auth.initialized) {
            props.checkAuth();
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.content}>
            <Header />
            <main>
                {props.children}
            </main>
            <Footer />
            <CartDialog />
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

const mapState = (state) => ({ auth: state.auth });
const mapDispatch = { checkAuth: AuthActions.checkAuth };

export default connect(mapState, mapDispatch)(Layout);
