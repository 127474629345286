import React, { useEffect, useState } from 'react';
import { StripeProvider } from 'react-stripe-elements';

const AsyncStripeProvider = ({ children }) => {
    const [stripe, setStripe] = useState(null);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (window.Stripe) {
                setStripe(
                    window.Stripe(process.env.GATSBY_STRIPE_KEY)
                );
            } else {
                document.querySelector('#stripe-js').addEventListener('load', () => {
                    // Create Stripe instance once Stripe.js loads
                    setStripe(
                        window.Stripe(process.env.GATSBY_STRIPE_KEY)
                    );
                });
            }
        }
    }, []);

    return (
        <StripeProvider stripe={stripe}>
            {children}
        </StripeProvider>
    );
}

export default AsyncStripeProvider;
