import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StoreContext, { defaultStoreContext } from '../context/store-context';
import { SHOPIFY_CHECKOUT_ID } from '../constants/local-storage';

class StoreProvider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            store: {
                ...defaultStoreContext,
                closeDialog: () => {
                    this.setState(state => ({
                        store: {
                            ...state.store,
                            dialog: false
                        },
                    }));
                },
                addVariantToCart: async (productId, variantId, quantity, attr = null) => {
                    if (variantId === '' || !quantity) {
                        console.error('Both a size and quantity are required.');
                        return;
                    }

                    quantity = parseInt(quantity, 10);

                    await this.setState(state => ({
                        store: {
                            ...state.store,
                            dialog: true,
                            adding: true,
                            available: true
                        },
                    }));

                    const { checkout, client } = this.state.store;
                    const checkoutId = checkout.id;

                    const product = await client.product.fetch(productId);

                    const result = product.variants.filter(
                        variant => variant.id === variantId
                    );

                    if (!(result && result.length && result[0].available)) {
                        console.log(result);
                        await this.setState(state => ({
                            store: {
                                ...state.store,
                                adding: false,
                                available: false
                            }
                        }));
                        return;
                    }

                    const lineItem = { variantId, quantity };

                    if (attr) {
                        lineItem.customAttributes = attr
                    };

                    const newCheckout = await client.checkout.addLineItems(checkoutId, [lineItem]);

                    await this.setState(state => ({
                        store: {
                            ...state.store,
                            checkout: newCheckout,
                            adding: false,
                        },
                    }));
                },
                removeLineItem: (client, checkoutID, lineItemID) => {
                    return client.checkout
                        .removeLineItems(checkoutID, [lineItemID])
                        .then(res => {
                            this.setState(state => ({
                                store: {
                                    ...state.store,
                                    checkout: res,
                                },
                            }))
                        });
                },
                updateLineItem: (client, checkoutID, lineItemID, quantity) => {
                    const lineItemsToUpdate = [
                        { id: lineItemID, quantity: parseInt(quantity, 10) },
                    ];

                    return client.checkout
                        .updateLineItems(checkoutID, lineItemsToUpdate)
                        .then(res => {
                            this.setState(state => ({
                                store: {
                                    ...state.store,
                                    checkout: res,
                                },
                            }))
                        });
                },
                addPrefillInfo: async (client, checkout, email, shippingAddress, activeSubscriber, discount_percent) => {
                    let lineItems = [];

                    if (checkout && checkout.lineItems) {
                        lineItems = checkout.lineItems.map((x) => (
                            {
                                variantId: x.variant.id,
                                quantity: x.quantity,
                                customAttributes: x.customAttributes
                            }
                        ));
                    }

                    let newCheckout;

                    try {
                        newCheckout = await client.checkout.create({
                            email,
                            lineItems,
                            shippingAddress
                        });
                    } catch {
                        // address could be invalid, if so just create new checkout with email
                        newCheckout = await client.checkout.create({
                            email,
                            lineItems
                        });
                    }

                    if (!activeSubscriber) {
                        // not an active subscriber, no discount for you!
                        await this.setCheckoutInState(newCheckout);
                        return;
                    }

                    // active subscriber, apply the discount code
                    try {
                        const memberCheckout = await client.checkout.addDiscount(
                            newCheckout.id,
                            `MEMBER${discount_percent}`
                        );

                        await this.setCheckoutInState(memberCheckout);
                    } catch {
                        // the discount code may not be available to them
                        await this.setCheckoutInState(newCheckout);
                    }
                },
                deletePrefillInfo: async (client, checkout) => {
                    let lineItems = [];

                    if (checkout && checkout.lineItems) {
                        lineItems = checkout.lineItems.map((x) => (
                            {
                                variantId: x.variant.id,
                                quantity: x.quantity,
                                customAttributes: x.customAttributes
                            }
                        ));
                    }

                    const newCheckout = await client.checkout.create({ lineItems });

                    await this.setCheckoutInState(newCheckout);
                }
            }
        };
    }

    componentDidMount() {
        this.initializeCheckout();
    }

    async setCheckoutInState(checkout) {
        const isBrowser = typeof window !== 'undefined';

        if (isBrowser) {
            localStorage.setItem(SHOPIFY_CHECKOUT_ID, checkout.id);
        }

        await this.setState(state => ({
            store: {
                ...state.store,
                checkout,
            },
        }));
    }

    async initializeCheckout() {
        // Check for an existing cart.
        const isBrowser = typeof window !== 'undefined';

        const existingCheckoutID = isBrowser
            ? localStorage.getItem(SHOPIFY_CHECKOUT_ID)
            : null;

        const createNewCheckout = () => this.state.store.client.checkout.create();
        const fetchCheckout = id => this.state.store.client.checkout.fetch(id);

        if (existingCheckoutID) {
            try {
                const checkout = await fetchCheckout(existingCheckoutID);

                // Make sure this cart hasn’t already been purchased.
                if (!checkout.completedAt) {
                    await this.setCheckoutInState(checkout);
                    return;
                }
            } catch (e) {
                localStorage.setItem(SHOPIFY_CHECKOUT_ID, null);
            }
        }

        const newCheckout = await createNewCheckout();
        await this.setCheckoutInState(newCheckout);
    }

    render() {
        return (
            <StoreContext.Provider value={this.state.store}>
                {this.props.children}
            </StoreContext.Provider>
        );
    }
}

StoreProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export default StoreProvider;
