import React, { useContext, useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    CircularProgress,
    IconButton
} from '@material-ui/core';
import { X, ShoppingCart } from 'react-feather';
import { Link } from 'gatsby';

import StoreContext from '../context/store-context';
import ImgLoader from './img-loader';
import ProductPrice from './product-price';
import { getHandle } from '../utils/product-utils';
import { modifyUrlQuery } from '../utils/uri-utils';

import styles from '../styles/cart-dialog.module.scss';

const CartDialog = () => {
    const context = useContext(StoreContext);
    const { adding, available, checkout } = context;

    const [items, setItems] = useState(checkout ? checkout.lineItems : []);

    useEffect(() => {
        setItems(checkout ? checkout.lineItems : []);
    }, [checkout]);

    let title;

    if (adding) {
        title = 'Adding to cart...';
    } else if (!available) {
        title = 'Item Unavailable';
    } else {
        title = 'In Your Cart';
    }

    const empty = !(!adding && items && items.length);

    const LineItem = ({ item }) => {
        const [removing, setRemoving] = useState(false);

        const removeItem = () => {
            setRemoving(true);
            context.removeLineItem(context.client, checkout.id, item.id);
        };

        const handle = getHandle(item);

        const imgurl = modifyUrlQuery(
            item.variant.image.src,
            {
                width: 96
            }
        );

        return (
            <li key={item.id}>
                <Link
                    to={`/product/${handle}/`}
                    className={styles.imglink}
                    onClick={context.closeDialog}
                >
                    <ImgLoader
                        className={styles.imgload}
                        loadingClass={styles.loading}
                        src={imgurl}
                        alt={item.title}
                    />
                </Link>
                <div className={styles.details}>
                    <Link
                        to={`/product/${handle}/`}
                        className={styles.title}
                        onClick={context.closeDialog}
                    >
                        {`${item.title}${item.variant.title !== 'Default Title' ? ' - ' + item.variant.title : ''}`}
                    </Link>
                    <small>Quantity: {item.quantity}</small>
                </div>
                <div className={styles.actions}>
                    <strong className={styles.price}>
                        <ProductPrice
                            value={item.variant.price}
                            showDecimal={true}
                        />
                    </strong>
                    <Button
                        variant='text'
                        size='small'
                        className={styles.remove}
                        disabled={removing}
                        onClick={removeItem}
                    >
                        {removing &&
                            <CircularProgress
                                color='inherit'
                                size={16}
                                classes={{ root: styles.progress }}
                            />
                        }
                        Remove
                    </Button>
                </div>
            </li >
        );
    };

    const LineItems = () => {
        if (!available) {
            return (
                <li className={styles.empty}>Sorry, that item is currently unavailable.</li>
            );
        }

        if (empty) {
            return (
                <li className={styles.empty}>Your cart is empty. Add some stuff!</li>
            );
        }

        return items.map((item) => (
            <LineItem item={item} />
        ));
    };

    const Content = () => {
        if (adding) {
            return (
                <section className={styles.loader}>
                    <CircularProgress color='primary' />
                </section>
            );
        }

        return (
            <section className={styles.cart}>
                <ul>
                    <LineItems />
                </ul>
            </section>
        );
    }

    return (
        <Dialog
            open={context.dialog}
            onClose={context.closeDialog}
            fullWidth={true}
            maxWidth='xs'
            aria-labelledby='cart_dialog_title'
            classes={{ paper: styles.dialog }}
        >
            <IconButton
                aria-label='close'
                size='small'
                className={styles.close}
                onClick={context.closeDialog}>
                <X />
            </IconButton>
            <DialogTitle
                id='cart_dialog_title'
                disableTypography={true}
                classes={{ root: styles.title }}
            >
                <h6>{title}</h6>
            </DialogTitle>
            <DialogContent
                classes={{ root: styles.content }}
            >
                <Content />
            </DialogContent>
            <DialogActions>
                <Button
                    color='primary'
                    component={Link}
                    to='/cart/'
                    disabled={empty}
                    onClick={context.closeDialog}
                >
                    <ShoppingCart size={18} className={styles.icon} />
                    GO TO CART
                </Button>
                <Button
                    color='default'
                    onClick={context.closeDialog}
                >
                    CLOSE
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default CartDialog;