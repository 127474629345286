import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

const Theme = createMuiTheme({
	typography: {
		fontFamily: "'Nunito', sans-serif"
	},
	palette: {
		default: {
			main: '#313131',
			light: '#5a5a5a',
			dark: '#222222'
		},
		primary: {
			main: '#b76e79',
			light: '#eb9da8',
			dark: '#85414d'
		},
		secondary: blue
	},
	overrides: {
		MuiInputBase: {
			input: {
				fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
			}
		},
		MuiButton: {
			root: {
				borderRadius: '0',
				fontWeight: '600'
			},
			text: {
				borderRadius: '2px'
			},
			label: {
				textAlign: 'center'
			},
			outlined: {
				fontWeight: '700'
			},
			contained: {
				backgroundColor: '#313131',
				color: '#FFFFFF',
				'&:hover': {
					backgroundColor: '#454545',
					'@media (hover: none)': {
						backgroundColor: '#454545'
					}
				}
			}
		},
		MuiOutlinedInput: {
			notchedOutline: {
				borderColor: '#DDDDDD !important'
			},
			inputMarginDense: {
				paddingTop: '15px',
				paddingBottom: '15px'
			}
		},
		MuiInputLabel: {
			outlined: {
				'&$marginDense': {
					transform: 'translate(14px, 17px) scale(1)'
				},
				'&$shrink': {
					backgroundColor: '#FFFFFF',
					paddingLeft: '1px',
					paddingRight: '1px'
				}
			}
		},
		MuiTooltip: {
			tooltip: {
				fontSize: '0.8125rem',
				padding: '0.5rem 1rem'
			}
		},
		MuiFormHelperText: {
			contained: {
				margin: '8px 12px 6px 12px'
			}
		},
		MuiDialog: {
			paperFullWidth: {
				width: '100vw'
			},
			paperScrollPaper: {
				maxHeight: 'calc(100vh - 2rem)'
			}
		}
	}
});

export default Theme;