import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { formatPrice, formatCleanPrice } from '../utils/format-number';

import styles from '../styles/product-price.module.scss';

const calcDiscountPrice = (value, discount_percent) => (
    value - (value * discount_percent / 100.00)
);

const ProductPrice = ({ auth, value, className, showCompare, showPerk, showDecimal }) => {
    const discount = Boolean(auth.user && auth.user.active_subscriber);
    const discount_percent = discount ? auth.user.discount_percent : 0;
    const format = showDecimal ? formatPrice : formatCleanPrice;

    return (
        <>
            <span className={clsx(styles.price, discount ? styles.discount : null, className)}>
                <ins>{format(discount ? calcDiscountPrice(value, discount_percent) : value)}</ins>
                {showCompare && discount &&
                    <del><small>{format(value)}</small></del>
                }
            </span>
            {showPerk && discount &&
                <>
                    <br />
                    <span className={styles.perk}>{`${discount_percent}% MEMBER DISCOUNT`}</span>
                </>
            }
        </>
    );
};

ProductPrice.defaultProps = {
    value: 0,
    showCompare: false,
    showPerk: false,
    showDecimal: false
};

ProductPrice.props = {
    value: PropTypes.number,
    className: PropTypes.string,
    showCompare: PropTypes.bool,
    showPerk: PropTypes.bool,
    showDecimal: PropTypes.bool
};

const mapState = (state) => ({
    auth: state.auth
});

export default connect(mapState, null)(ProductPrice);
