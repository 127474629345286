module.exports = [{
      plugin: require('C:/Users/ryank/OneDrive/Documents/GitHub/roselium-web/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/ryank/OneDrive/Documents/GitHub/roselium-web/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true},"disableAutoprefixing":false,"disableMinification":false},
    },{
      plugin: require('C:/Users/ryank/OneDrive/Documents/GitHub/roselium-web/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5FQKHDJ"},
    },{
      plugin: require('C:/Users/ryank/OneDrive/Documents/GitHub/roselium-web/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/ryank/OneDrive/Documents/GitHub/roselium-web/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
