// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\Users\\ryank\\OneDrive\\Documents\\GitHub\\roselium-web\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-store-category-js": () => import("C:\\Users\\ryank\\OneDrive\\Documents\\GitHub\\roselium-web\\src\\templates\\store-category.js" /* webpackChunkName: "component---src-templates-store-category-js" */),
  "component---src-templates-store-brand-js": () => import("C:\\Users\\ryank\\OneDrive\\Documents\\GitHub\\roselium-web\\src\\templates\\store-brand.js" /* webpackChunkName: "component---src-templates-store-brand-js" */),
  "component---src-templates-product-js": () => import("C:\\Users\\ryank\\OneDrive\\Documents\\GitHub\\roselium-web\\src\\templates\\product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-partner-js": () => import("C:\\Users\\ryank\\OneDrive\\Documents\\GitHub\\roselium-web\\src\\templates\\partner.js" /* webpackChunkName: "component---src-templates-partner-js" */),
  "component---src-pages-404-js": () => import("C:\\Users\\ryank\\OneDrive\\Documents\\GitHub\\roselium-web\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("C:\\Users\\ryank\\OneDrive\\Documents\\GitHub\\roselium-web\\src\\pages\\about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-cart-js": () => import("C:\\Users\\ryank\\OneDrive\\Documents\\GitHub\\roselium-web\\src\\pages\\cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-subscribe-js": () => import("C:\\Users\\ryank\\OneDrive\\Documents\\GitHub\\roselium-web\\src\\pages\\checkout\\subscribe.js" /* webpackChunkName: "component---src-pages-checkout-subscribe-js" */),
  "component---src-pages-confirm-email-js": () => import("C:\\Users\\ryank\\OneDrive\\Documents\\GitHub\\roselium-web\\src\\pages\\confirm-email.js" /* webpackChunkName: "component---src-pages-confirm-email-js" */),
  "component---src-pages-forgot-password-js": () => import("C:\\Users\\ryank\\OneDrive\\Documents\\GitHub\\roselium-web\\src\\pages\\forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\ryank\\OneDrive\\Documents\\GitHub\\roselium-web\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("C:\\Users\\ryank\\OneDrive\\Documents\\GitHub\\roselium-web\\src\\pages\\login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-monthly-jewelry-subscription-js": () => import("C:\\Users\\ryank\\OneDrive\\Documents\\GitHub\\roselium-web\\src\\pages\\monthly-jewelry-subscription.js" /* webpackChunkName: "component---src-pages-monthly-jewelry-subscription-js" */),
  "component---src-pages-my-account-js": () => import("C:\\Users\\ryank\\OneDrive\\Documents\\GitHub\\roselium-web\\src\\pages\\my-account.js" /* webpackChunkName: "component---src-pages-my-account-js" */),
  "component---src-pages-our-partners-js": () => import("C:\\Users\\ryank\\OneDrive\\Documents\\GitHub\\roselium-web\\src\\pages\\our-partners.js" /* webpackChunkName: "component---src-pages-our-partners-js" */),
  "component---src-pages-privacy-policy-js": () => import("C:\\Users\\ryank\\OneDrive\\Documents\\GitHub\\roselium-web\\src\\pages\\privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-reset-password-js": () => import("C:\\Users\\ryank\\OneDrive\\Documents\\GitHub\\roselium-web\\src\\pages\\reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-shipping-and-returns-js": () => import("C:\\Users\\ryank\\OneDrive\\Documents\\GitHub\\roselium-web\\src\\pages\\shipping-and-returns.js" /* webpackChunkName: "component---src-pages-shipping-and-returns-js" */),
  "component---src-pages-store-all-js": () => import("C:\\Users\\ryank\\OneDrive\\Documents\\GitHub\\roselium-web\\src\\pages\\store\\all.js" /* webpackChunkName: "component---src-pages-store-all-js" */),
  "component---src-pages-store-index-js": () => import("C:\\Users\\ryank\\OneDrive\\Documents\\GitHub\\roselium-web\\src\\pages\\store\\index.js" /* webpackChunkName: "component---src-pages-store-index-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("C:\\Users\\ryank\\OneDrive\\Documents\\GitHub\\roselium-web\\src\\pages\\terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-unsubscribe-js": () => import("C:\\Users\\ryank\\OneDrive\\Documents\\GitHub\\roselium-web\\src\\pages\\unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */)
}

