import * as Types from '../constants/action-types';

const initialState = {
    initialized: false,
    authenticated: false,
    claims: null,
    user: null
};

export default (state = initialState, { type, data }) => {
    switch (type) {
        case Types.SET_AUTH:
            return {
                ...state,
                initialized: true,
                authenticated: data.authenticated,
                claims: data.claims,
                user: data.user
            };

        case Types.CLEAR_AUTH:
            return {
                ...state,
                initialized: true,
                authenticated: false,
                claims: null,
                user: null
            };

        default:
            return state;
    }
};
