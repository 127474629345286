import qs from 'query-string';

export function modifyUrlQuery(url, newparams) {
    const parse = qs.parseUrl(url, { sort: false });

    const newquery = {
        ...parse.query,
        ...newparams
    };

    const newqs = qs.stringify(newquery, { sort: false });

    return `${parse.url}?${newqs}`;
}

export function prepareHandle(text) {
    return encodeURIComponent(text.toLowerCase().replace(/\s+/gi, '-'));
}