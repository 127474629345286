import * as Types from '../constants/action-types';

const initialState = {
    step: 0,
    metadata: {}
};

export default (state = initialState, { type, data }) => {
    switch (type) {
        case Types.SET_CHECKOUT_DATA:
            return {
                ...state,
                step: data.step,
                metadata: data.metadata
            };

        case Types.CLEAR_CHECKOUT_DATA:
            return {
                ...initialState
            };

        default:
            return state;
    }
};
