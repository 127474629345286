import * as Types from '../constants/action-types';

const initialState = {
    initialized: false,
    fname: null,
    lname: null,
    defaultCard: null,
    shipping: null,
    subscriptions: []
};

export default (state = initialState, { type, data }) => {
    switch (type) {
        case Types.SET_ACCOUNT_DETAILS:
            return {
                ...state,
                initialized: true,
                fname: data.fname,
                lname: data.lname,
                defaultCard: data.defaultCard,
                shipping: data.shipping,
                subscriptions: data.subscriptions
            };

        case Types.CLEAR_ACCOUNT_DETAILS:
            return {
                ...initialState
            };

        default:
            return state;
    }
};
