import React, { useState } from 'react';
import clsx from 'clsx';

import styles from '../styles/img-loader.module.scss';

const cache = {};

const ImgLoader = ({ className, loadingClass, src, alt, ...other }) => {
    const [loading, setLoading] = useState(cache[src] !== true);

    const onLoad = () => {
        setLoading(false);
        cache[src] = true;
    };

    return (
        <div className={clsx(styles.loader, className, loading && styles.loading, loading && loadingClass)}>
            <img
                onLoad={onLoad}
                src={src}
                alt={alt}
                {...other}
            />
        </div>
    );
};

export default ImgLoader;