import { combineReducers } from 'redux';

import AccountReducer from './account-reducer';
import AuthReducer from './auth-reducer';
import CheckoutReducer from './checkout-reducer';

const appReducer = combineReducers({
    account: AccountReducer,
    auth: AuthReducer,
    checkout: CheckoutReducer
});

export default appReducer;
