import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';

import configStore from '../stores/config-store';
import theme from '../theme/mui-theme';
import StoreProvider from './store-provider';
import AsyncStripeProvider from './async-stripe-provider';

const ProviderWrapper = ({ element }) => {
    const store = configStore();

    return (
        <Provider store={store}>
            <AsyncStripeProvider>
                <StoreProvider>
                    <ThemeProvider theme={theme}>
                        {element}
                    </ThemeProvider>
                </StoreProvider>
            </AsyncStripeProvider>
        </Provider>
    );
};

export default ProviderWrapper;
