import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import { Button, CircularProgress } from '@material-ui/core';
import { Instagram, Facebook, Twitter } from 'react-feather';
import validator from 'validator';

import * as Errors from '../constants/errors';

import * as AccountActions from '../actions/account-actions';

import styles from '../styles/footer.module.scss';

const Footer = ({ subscribeNewsletter }) => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState(null);
    const [subscribed, setSubscribed] = useState(false);

    const onChange = (e) => {
        setEmail(e.target.value);
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        setErrors(null);
        setSubscribed(false);

        if (validator.isEmpty(email)) {
            setErrors(['Please enter your email.']);
            return false;
        } else if (!validator.isEmail(email)) {
            setErrors(['Please enter a valid email.']);
            return false;
        }

        setLoading(true);

        const result = await subscribeNewsletter({ email });

        setLoading(false);

        if (result.ok) {
            setEmail('');
            setSubscribed(true);
            return true;
        }

        switch (result.error.type) {
            case Errors.INVALID_EMAIL:
                setErrors([
                    'Sorry, this email may be invalid or unreachable.',
                    'Please try again with a different email.'
                ]);
                break;

            default:
                setErrors([
                    'Sorry, an error occurred.',
                    'Please try again.'
                ]);
                break;
        }

        return false;
    };

    const onContactUs = (e) => {
        e.preventDefault();
        window.zE('webWidget', 'open');
        return false;
    };

    return (
        <footer>
            <div className={styles.content}>
                <nav>
                    <div className={styles.widget}>
                        <h5>FOLLOW US</h5>
                        <ul className={styles.social}>
                            <li>
                                <a href='https://www.instagram.com/roselium_jewelry/' title='Instagram' aria-label='Instagram' target='_blank' rel='noopener noreferrer'>
                                    <Instagram size={22} />
                                </a>
                            </li>
                            <li>
                                <a href='https://www.facebook.com/roseliumjewelry/' title='Facebook' aria-label='Facebook' target='_blank' rel='noopener noreferrer'>
                                    <Facebook size={22} />
                                </a>
                            </li>
                            <li>
                                <a href='https://twitter.com/roseliumjewelry' title='Twitter' aria-label='Twitter' target='_blank' rel='noopener noreferrer'>
                                    <Twitter size={22} />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.widget}>
                        <h5>CONTACT US</h5>
                        <ul>
                            <li>
                                <a href='mailto:hello@roselium.com'>hello@roselium.com</a>
                            </li>
                            <li>
                                <a href='tel:‪+18779599877'>(877) 959-9877</a>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.widget}>
                        <h5>SHOP</h5>
                        <ul>
                            <li>
                                <Link to='/monthly-jewelry-subscription/'>Become a Member</Link>
                            </li>
                            <li>
                                <Link to='/store/'>Shop Our Store</Link>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.widget}>
                        <h5>EXPLORE</h5>
                        <ul>
                            <li>
                                <Link to='/our-partners/'>Our Partners</Link>
                            </li>
                            <li>
                                <Link to='/about-us/'>About Us</Link>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.widget}>
                        <h5>INFO</h5>
                        <ul>
                            <li>
                                <Link to='/#how_it_works'>How it Works</Link>
                            </li>
                            <li>
                                <Link to='/#faq'>FAQ</Link>
                            </li>
                            <li>
                                <Link
                                    to='/'
                                    onClick={onContactUs}
                                >
                                    Help
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.widget}>
                        <h5>LEGAL</h5>
                        <ul>
                            <li>
                                <Link to='/shipping-and-returns/'>Shipping and Returns</Link>
                            </li>
                            <li>
                                <Link to='/terms-and-conditions/'>Terms and Conditions</Link>
                            </li>
                            <li>
                                <Link to='/privacy-policy/'>Privacy Policy</Link>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.widget}>
                        <h5>NEWSLETTER</h5>
                        {subscribed &&
                            <div className={styles.subscribed}>Thank you for subscribing!</div>
                        }
                        {errors &&
                            <ul className={styles.errors}>
                                {errors.map((x, i) => (
                                    <li key={i}>{x}</li>
                                ))}
                            </ul>
                        }
                        <form
                            noValidate
                            className={styles.newsletterForm}
                            onSubmit={onSubmit}
                            disabled={loading}
                        >
                            <label
                                htmlFor='newsletter_email'
                                hidden
                            >
                                Your email address
                            </label>
                            <input
                                type='email'
                                id='newsletter_email'
                                placeholder='Your email address'
                                aria-label='Your email address'
                                onChange={onChange}
                                value={email}
                            />
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                                disabled={loading}
                                className={styles.signup}
                            >
                                {loading ?
                                    <CircularProgress
                                        color='inherit'
                                        size={16}
                                        className={styles.loading}
                                    /> :
                                    'Sign Up'
                                }
                            </Button>
                        </form>
                    </div>
                </nav>
                <div className={styles.copyright}>
                    © 2019-2020 ROSELIUM INC
                </div>
            </div>
        </footer>
    );
}

Footer.propTypes = {
    subscribeNewsletter: PropTypes.func.isRequired
};

const mapDispatch = {
    subscribeNewsletter: AccountActions.subscribeNewsletter
};

export default connect(null, mapDispatch)(Footer);
